function refiners() {
    var btn = $(".cajaselect");
    var act = $("div.active");
    var idDiv = act.attr("id");
    var selectElement = "div#" + idDiv + ".cajaselect.active";
    if (idDiv != undefined) {
        var deleteId = "div#" + idDiv + ".cajaselect";
        $(selectElement).on("click", function() {
            var idElement = selectElement + " > ul";
            $(idElement).css("display", "none");
            $(idElement).parent().closest(".cajaselect").removeClass("active");
            $(deleteId).removeAttr("id");
            idDiv = undefined;
            selectElement = 0;
            btn.length = 0;
            deleteId = 0;
            $(document).finish()
        })     
    }
    if (btn.length > 0) {
        btn.on("click", "span", function() {
            event.preventDefault();
            var Id = $(this).attr("id");
            var idElement = "#" + $(this).attr("id");
            $(idElement).next("ul").css("display", "block");
            $(idElement).parent().closest(".cajaselect").addClass("active");
            $(idElement).parent().closest(".cajaselect").attr("id", Id);
            $(document).finish()
        })
    }
}

function refinersOut() {
    var btn = $(".cajaselect");
    var act = $("div.active");
    var idDiv = act.attr("id");
    var selectElement = "div#" + idDiv + ".cajaselect.active";
    if (idDiv != undefined) {
        var deleteId = "div#" + idDiv + ".cajaselect";           
        
            var idElement = selectElement + " > ul";
            $(idElement).css("display", "none");
            $(idElement).parent().closest(".cajaselect").removeClass("active");
            $(deleteId).removeAttr("id");
            idDiv = undefined;
            selectElement = 0;
            btn.length = 0;
            deleteId = 0;
            $(document).finish()  
        
    }
   
}
